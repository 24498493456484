<template>
    <b-card title="Edit Social Media Links">
        <validation-observer ref="simpleRules">
            <b-form @submit.prevent>
                <b-row>
                   <!-- Facebook Url -->
                  <b-col cols="12">
                      <b-form-group label="Facebook Url" label-for="Facebook Url">
                          <validation-provider name="Facebook Url" rules="required|max:50" #default="{ errors }">
                              <b-input-group class="input-group-merge"
                                  :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                  <b-input-group-prepend is-text>
                                      <feather-icon icon="UserIcon" />
                                  </b-input-group-prepend>

                                  <b-form-input id="facebook" v-model="item.facebook"
                                      :state="errors.length > 0 ? false : null" placeholder="Facebook Url" />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                      </b-form-group>
                  </b-col>

                  <!-- Instagram Url -->
                  <b-col cols="12">
                      <b-form-group label="Instagram Url" label-for="Instagram Url">
                          <validation-provider name="Instagram Url" rules="required|max:50" #default="{ errors }">
                              <b-input-group class="input-group-merge"
                                  :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                  <b-input-group-prepend is-text>
                                      <feather-icon icon="UserIcon" />
                                  </b-input-group-prepend>

                                  <b-form-input id="instagram" v-model="item.instagram"
                                      :state="errors.length > 0 ? false : null" placeholder="Instagram Url" />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                      </b-form-group>
                  </b-col>

                  <!-- Twitter Url -->
                  <b-col cols="12">
                      <b-form-group label="Twitter Url" label-for="Twitter Url">
                          <validation-provider name="Twitter Url" rules="required|max:50" #default="{ errors }">
                              <b-input-group class="input-group-merge"
                                  :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                  <b-input-group-prepend is-text>
                                      <feather-icon icon="UserIcon" />
                                  </b-input-group-prepend>

                                  <b-form-input id="twitter" v-model="item.twitter"
                                      :state="errors.length > 0 ? false : null" placeholder="Twitter Url" />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                      </b-form-group>
                  </b-col>

                    <!-- submit and reset -->
                    <b-col>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit"
                            @click.prevent="validationForm" variant="primary" class="mr-1">
                            Submit
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                            @click="reset">
                            Reset
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormText,
    BFormDatalist,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
//Database
import { db, storage } from "@/main";
import firebase from "firebase";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//Vee-Validate 
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    required
} from '@validations'
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BFormCheckbox,
        BForm,
        BFormText,
        BButton,
        BFormDatalist,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormFile,
        BFormSelect,
        BFormTextarea,
        BImg,
        ValidationProvider,
        ValidationObserver,
        required,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            item:{},
            selected: '',
            checkedNames:"",
            options: [],
            previewImg:'',
            profileImg:'',
            facebook:'',
            twitter:'',
            instagram:''
        }
    },
    created() {
        this.getItem();
    },
    computed: {
        validation() {
            return this.name.length > 4 && this.name.length < 13
        },
    },
    methods: {
        addFind: function () {
            this.finds.push({ value: '' });
          },
          deleteFind: function (index) {
            console.log(index);
            console.log(this.finds);
            this.finds.splice(index, 1);
          },
        reset() {
            this.facebook = '',
            this.twitter = '',
            this.instagram = '';
        },

        getItem()
            {
                var id = this.$route.params.id;

                db.collection('sociallinks').doc(id).get().then((doc) => {
                    console.log(doc.data());
                    this.item = doc.data();
                    // this.previewImg = doc.data().image;
                })
            },

        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    // alert('form submitted!')
                    this.submit();
                }
            })
        },
        submit() {
            store.dispatch('app/commitActivateLoader');
            if(this.checkedNames == true){
            this.blogshowoption = 1;
			}else if(this.checkedNames == false){
			this.blogshowoption = 0;
			} 
            // alert(this.blogshowoption)
             var date = new Date().toLocaleString();//.toISOString().slice(0, 10);
             var id = this.$route.params.id;
                var  date = new Date().toLocaleString();//.toISOString().slice(0, 10);

                                db.collection("sociallinks")
                                .doc(id)
                                .update({
                                facebook: this.item.facebook,
                                twitter: this.item.twitter,
                                instagram: this.item.instagram,
                                modified: date,
                                })
                                .then((doc) => {
                                    console.log("Added Successfully!");
                                    this.$router.push({ name: 'sociallinks-index' });
                                })
                            // var id = localStorage.getItem("userSessionId");
                            // console.log('rrr ' + id);
        },
        showNotification(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    variant,
                },
            })
        },
    },
}
</script>